<template>
  <div class="project-detail-wrapper">
    <!--  <transition name="fade" mode="out-in" v-if="this.project">
      <div v-if="!detail" class="intro-page">
        <div class="project-left">
          <img :src="this.getImageUrl(project.attributes.Logo)" />
        </div>
        <div
          class="project-right"
          @mouseover="this.isHovering = true"
          @mouseout="this.isHovering = false"
          :class="{ 'inside-active': this.isHovering }"
        >
          <div class="top">
            <div class="title-wrapper">
              <h1>
                {{ project.attributes.Intro.Title }}
              </h1>
              <p>
                {{ $t("version") + " : " + project.attributes.Intro.Version }}
              </p>
            </div>
          </div>
          <div class="bottom">
            <p>
              {{ project.attributes.Intro.Description }}
            </p>
            <div class="link-wrapper" @click="this.openDetail()">Enter</div>
          </div>
        </div>
      </div>
    </transition> -->
    <transition v-if="this.project" name="fade" mode="out-in">
      <div class="detail-page">
        <div class="header container wide"></div>
        <div class="dashboard extend-both">
          <div class="sidebar">
            <div class="project-title-wrapper">
              <div class="label">GUIDELINES</div>
              <h2 class="title">{{ project.attributes.Name }}</h2>
            </div>

            <div id="drag-me">
              <svg>
                <use href="@/assets/icons.svg#ico-drag" />
              </svg>
            </div>
            <SidebarSection
              @setView="this.setView"
              @openSection="this.openSection"
              @openInnerSection="this.openInnerSection"
              v-for="section in this.sections"
              :key="section"
              :name="section.name"
              :content="section.content"
            ></SidebarSection>
          </div>
          <div class="main-section">
            <div class="right-wrapper">
              <SocialIcons :icons="this.social"></SocialIcons>
            </div>
            <div class="download_section">
              <div class="download-wrapper" v-for="file in files">
                <a :href="this.getFileUrl(file)" target="_blank">
                  <img src="@/assets/download.png" />
                  <div class="filename">
                    {{ file.attributes.name }}
                  </div>
                </a>
              </div>
            </div>
            <div
              class="social-navigation"
              v-show="
                possible_views &&
                possible_views.length > 0 &&
                possible_views[0].social != 'Sem Categoria'
              "
            >
              <div
                class="option"
                :class="{ active: this.social_network == social }"
                v-for="social in possible_socials"
                :key="social"
                @click="this.changeSocialNetwork(social)"
              >
                {{ social }}
              </div>
            </div>
            <div
              class="display-size-navigation"
              v-show="possible_views && possible_views.length > 0 && have_displays"
            >
              <div
                class="option"
                :class="{
                  active:
                    this.display_size == possible_view.display &&
                    possible_view.social == this.social_network,
                  hide: possible_view.social != this.social_network,
                }"
                v-for="possible_view in possible_views"
                :key="possible_view"
                @click="this.changeDisplaySize(possible_view.display)"
              >
                {{ possible_view.display }}
              </div>
            </div>
            <div
              class="print-result"
              :class="{ 'back-to-top': this.btt, 'go-to-bottom': this.gtb }"
            >
              <div class="scroll-top" @click="scrollToTop()">
                <div class="svg-wrapper">
                  <svg>
                    <use href="@/assets/icons.svg#ico-arrow" />
                  </svg>
                </div>
              </div>
              <div class="view-wrapper" v-for="view in views">
                <iframe
                  v-lazy="this.fixIframe(content.iframe)"
                  :loading="this.active_view != content.id ? 'lazy' : 'eager'"
                  :key="content"
                  v-for="content in view.attributes.view_details"
                  :data-id="content.id"
                  v-show="this.active_view == content.id"
                  :data-viewid="content.id"
                >
                </iframe>
              </div>
              <div class="scroll-bottom" @click="scrollToBottom()">
                <div class="svg-wrapper">
                  <svg>
                    <use href="@/assets/icons.svg#ico-arrow" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { strapi_api } from "@/scripts/strapi_api.js";
import { login_info } from "@/scripts/global.js";

var startX, startWidth;

export default {
  name: "projectDetail",
  data() {
    return {
      strapi_api,
      login_info,
      project: false,
      detail: false,
      views: [],
      social: [],
      sections: [
        { name: "Setup", content: [] },
        { name: "Design", content: [] },
        { name: "Content", content: [] },
        { name: "Campaigns", content: [] },
        { name: "Activations", content: [] },
        { name: "Elements", content: [] },
        { name: "Cards", content: [] },
        { name: "Components", content: [] },
        { name: "Themes", content: [] },
      ],
      social_network: "",
      display_size: "",
      content: "",
      possible_views: [],
      possible_socials: [],
      have_displays: false,
      files: [],
      isHovering: false,
      active_view: null,
      btt: false,
      gtb: true,
      drag: false,
    };
  },
  methods: {
    getImageUrl(imageAttr) {
      return this.strapi_api.get_strapi_bo_url() + imageAttr.data.attributes.url;
    },
    getFileUrl(file) {
      return this.strapi_api.get_strapi_bo_url() + file.attributes.url;
    },
    scrollToTop() {
      if (
        document.querySelector("footer").getBoundingClientRect().top -
          window.innerHeight <
        0
      ) {
        this.scrollToCenter();
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    scrollToCenter() {
      window.scroll({
        top:
          document.querySelector(".main-section").getBoundingClientRect().top +
          window.scrollY,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollToBottom() {
      if (document.querySelector(".main-section").getBoundingClientRect().top > 0) {
        this.scrollToCenter();
      } else {
        window.scroll({
          top: document.body.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    getproject(slug, view = false) {
      //TODO find a way to avoid passing axios here
      let query =
        "populate=organization,organization.Logo,Views,Intro,Logo,Social,Views.view_details.Files,Views.Categoria,Editors";
      this.strapi_api.get_strapi(this.axios, "projects/" + slug, query).then(
        (response) => {
          if (!response) {
            document.querySelector(".fullscreen-modal").classList.add("display");
            document.querySelector(".fullscreen-modal").classList.add("show");
            return;
          }
          let userData = JSON.parse(sessionStorage.getItem("userData"));
          let userHasPermissions = false;
          let alias = this.login_info.get_alias();

          let organization_alias =
            response.data.data.attributes.organization.data.length > 0 &&
            response.data.data.attributes.organization.data[0].attributes.Alias == alias;

          this.login_info.set_orgLogo(
            this.strapi_api.get_strapi_bo_url() +
              response.data.data.attributes.organization.data.attributes.Logo.data
                .attributes.url
          );
          //if (organization_alias) {
          if (this.login_info.get_absolute_admin()) {
            userHasPermissions = true;
          } else {
            response.data.data.attributes.Editors.data.forEach((element) => {
              if (element.id == userData.id || this.login_info.get_absolute_admin()) {
                userHasPermissions = true;
              }
            });
          }

          //}

          if (!userHasPermissions) {
            document.querySelector(".fullscreen-modal").classList.add("display");
            document.querySelector(".fullscreen-modal").classList.add("show");
            return;
          }

          //Promisse returned successfully
          this.project = response.data.data;

          let social_final = [];
          this.project.attributes.Social.forEach((element) => {
            let fixedelement = {
              name: element.social_network.toLowerCase().replaceAll(" ", "-"),
              url: element.url,
              target: "_blank",
            };
            social_final.push(fixedelement);
          });
          this.social = social_final;
          if (this.project.attributes.Views.data.length > 0) {
            this.views = this.project.attributes.Views.data.filter(
              (view) => view.attributes.publishedAt != null
            );
            this.setupSections();
          }
        },
        () => {
          //something went wrong with promisse
        }
      );
    },
    openDetail() {
      this.detail = true;
      let scope = this;

      setTimeout(() => {
        this.setInitalView();
        scope.drag = document.querySelector("#drag-me");
        scope.drag.addEventListener(
          "mousedown",
          function (innerE) {
            startX = innerE.clientX;
            startWidth = parseInt(
              document.defaultView.getComputedStyle(scope.drag.parentNode).width,
              10
            );
            document.documentElement.addEventListener("mousemove", scope.doDrag, false);
            document.documentElement.addEventListener("mouseup", scope.stopDrag, false);
          },
          false
        );
      }, 2000);
    },
    openSection(e) {
      e.target.closest(".sidebar-section").classList.toggle("opened");
      let content = e.target.nextElementSibling;
      if (e.target.closest(".label")) {
        content = e.target.closest(".label").nextElementSibling;
      }

      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    },
    openInnerSection(e) {
      e.target.closest(".dropdown-item").classList.toggle("opened");
      let content = e.target.closest(".dropdown-item").querySelector(".inner-list");
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
      let parent = e.target.closest(".dropdown-list");
      parent.style.maxHeight = parent.scrollHeight + content.scrollHeight + "px";
    },
    setupSections() {
      function compare(a, b) {
        if (a.rank < b.rank) {
          return -1;
        }
        if (a.rank > b.rank) {
          return 1;
        }
        return 0;
      }

      this.views.forEach((view) => {
        let fixSocial = this.fixViewDetails(view.attributes.view_details);
        if (view.attributes.Categoria.data == null) {
          this.sections
            .find((section) => section.name == view.attributes.Section)
            .content.push({
              name: view.attributes.Name,
              social: fixSocial,
              sub_items: false,
              rank: view.attributes.Rank,
            });
        } else {
          let local_section = this.sections.find(
            (section) => section.name == view.attributes.Section
          );
          let theme = view.attributes.Categoria.data.attributes.Name;

          let checkIfSubExists = local_section.content.some((obj) => obj.name == theme);
          if (!checkIfSubExists) {
            local_section.content.push({
              name: theme,
              sub_items: [
                {
                  name: theme,
                  social: fixSocial,
                  label: view.attributes.Name,
                  rank: view.attributes.Rank,
                },
              ],
              rank: view.attributes.Categoria.data.attributes.Rank,
            });
          } else {
            local_section.content
              .find((obj) => obj.name == theme)
              .sub_items.push({
                name: theme,
                social: fixSocial,
                label: view.attributes.Name,
                rank: view.attributes.Rank,
              });
          }
        }
      });

      this.sections.forEach((section) => {
        if (section.content.length == 0) {
          return;
        }
        section.content.sort(compare);
        section.content.forEach((innerElement) => {
          if (innerElement.sub_items) {
            innerElement.sub_items.sort(compare);
          }
        });
      });
    },
    setInitalView() {
      if (this.active_view) {
        let content = "";
        let inner_label = document.querySelector(
          '.inner-label[data-viewid*="' + this.active_view + '"]'
        );
        let final_label = document.querySelector(
          '.final-label[data-viewid*="' + this.active_view + '"]'
        );

        if (inner_label) {
          let ids = inner_label.getAttribute("data-viewid").split(",");
          let current_tab = 0;
          ids.forEach((element, counter) => {
            if (parseInt(element) == this.active_view) {
              current_tab = counter;
            }
          });

          inner_label.closest(".sidebar-section").querySelector(".label").click();
          inner_label.click();
          content = inner_label
            .closest(".sidebar-section")
            .querySelector(".dropdown-list");
          if (current_tab != 0) {
            setTimeout(() => {
              Array.from(document.querySelector(".social-navigation").children).forEach(
                (element, counter) => {
                  if (counter == current_tab) {
                    element.click();
                  }
                }
              );
              if (content.style.maxHeight) {
                content.style.maxHeight = null;
              } else {
                content.style.maxHeight = content.scrollHeight + "px";
              }
            }, 1000);
          }
        } else if (final_label) {
          let ids = final_label.getAttribute("data-viewid").split(",");
          let current_tab = 0;
          ids.forEach((element, counter) => {
            if (parseInt(element) == this.active_view) {
              current_tab = counter;
            }
          });

          final_label.closest(".dropdown-item").querySelector(".inner-label").click();
          final_label.click();
          content = final_label
            .closest(".sidebar-section")
            .querySelector(".dropdown-list");
          if (current_tab != 0) {
            setTimeout(() => {
              Array.from(document.querySelector(".social-navigation").children).forEach(
                (element, counter) => {
                  if (counter == current_tab) {
                    element.click();
                  }
                }
              );

              if (content.style.maxHeight) {
                content.style.maxHeight = null;
              } else {
                content.style.maxHeight = content.scrollHeight + "px";
              }
            }, 1000);
          }
        }
      } else {
        let inner_label = document.querySelector(".inner-label");
        let final_label = inner_label.querySelector(".final_label");

        if (inner_label) {
          let ids = inner_label.getAttribute("data-viewid").split(",");
          inner_label.closest(".sidebar-section").querySelector(".label").click();
          inner_label.click();

          if (final_label) {
            let ids = final_label.getAttribute("data-viewid").split(",");
            final_label.closest(".dropdown-item").querySelector(".inner-label").click();
            final_label.click();

            this.active_view = parseInt(ids[0]);
          } else {
            this.active_view = parseInt(ids[0]);
          }
        }
      }
    },
    setView(e, possible_view, id) {
      if (document.querySelector(".inner-label.active")) {
        document.querySelector(".inner-label.active").classList.remove("active");
      }

      if (document.querySelector(".final-label.active")) {
        document.querySelector(".final-label.active").classList.remove("active");
      }

      if (document.querySelector(".sidebar-section.active")) {
        document.querySelector(".sidebar-section.active").classList.remove("active");
      }

      e.target.classList.add("active");
      e.target.closest(".sidebar-section").classList.add("active");
      this.possible_views = possible_view;
      this.possible_socials = possible_view
        .map((item) => item.social)
        .filter((v, i, a) => a.indexOf(v) === i);

      if (possible_view.length > 0) {
        this.social_network = possible_view[0].social;
        this.display_size = possible_view[0].display;
        this.content = possible_view[0].content;
        this.have_displays = possible_view[0].display;
        if (possible_view[0].files.data == null) {
          this.files = false;
        } else {
          this.files = possible_view[0].files.data;
        }
      }
      if (e.isTrusted) {
        this.login_info.scrollTo(document.querySelector(".main-section"));
      }

      this.active_view = id;
      this.$router.push("/project/" + this.project.id + "/" + this.active_view);
    },
    fixViewDetails(obj) {
      let output = [];

      obj.forEach((possible_view) => {
        let display = possible_view.display_size;
        if (display) {
          display = display.replace("size_", "");
        }

        output.push({
          social: possible_view.social_network,
          id: possible_view.id,
          display: display,
          content: this.fixIframe(possible_view.iframe),
          files: possible_view.Files,
        });
      });
      return output;
    },
    fixIframe(iframe) {  
      if (iframe.indexOf("iframe") > 0) {
        const sources = iframe
          .match(/<iframe [^>]*src="[^"]*"[^>]*>/gm)
          .map((x) => x.replace(/.*src="([^"]*)".*/, "$1"));

        if (iframe.indexOf("embed.figma") > 0) {
          return sources[0] + "&hide-ui=1&scaling=scale-down-width&show-proto-sidebar=0";
        } else {
          let query_params = {};
          let splitted = sources[0].split("?")[1].split("&");

          let base_url = splitted[1].split("=")[1].split("%3F");

          let inner_query = base_url[1].split("%26");
          inner_query.forEach((element) => {
            let inner_args = element.split("%3D");
            query_params[inner_args[0]] = inner_args[1];
          });

          query_params["hide-ui"] = 1;
          query_params["scaling"] = "scale-down-width";
          query_params["show-proto-sidebar"] = 0;

          let build_url =
            "https://www.figma.com/embed?embed_host=share&url=" + base_url[0] + "%3F";
          Object.keys(query_params).forEach((key) => {
            build_url += key + "%3D" + query_params[key] + "%26";
          });
          return build_url;
        }
      } else {
        return process.env.VUE_APP_STORYBOOK_BASE_PATH + iframe;
      }
    },
    changeSocialNetwork(social) {
      this.possible_views.forEach((possible_view) => {
        if (possible_view.social == social) {
          this.social_network = possible_view.social;
          this.display_size = possible_view.display;
          this.content = possible_view.content;
          this.active_view = possible_view.id;
          this.login_info.scrollTo(document.querySelector(".main-section"));
          this.have_displays = possible_view.display;
          this.$router.push("/project/" + this.project.id + "/" + this.active_view);
        }
      });
    },
    changeDisplaySize(size) {
      this.possible_views.forEach((possible_view) => {
        if (
          possible_view.social == this.social_network &&
          possible_view.display == size
        ) {
          this.social_network = possible_view.social;
          this.display_size = possible_view.display;
          this.content = possible_view.content;
          this.active_view = possible_view.id;
          this.login_info.scrollTo(document.querySelector(".main-section"));
        }
      });
    },
    doDrag(e) {
      let newWidth = startWidth + e.clientX - startX;
      if (newWidth < 150) {
        newWidth = 150;
      }
      if (newWidth > 600) {
        newWidth = 600;
      }
      document.body.classList.add("noselect");
      this.drag.classList.add("dragging");
      this.drag.parentNode.style.width = newWidth + "px";
    },

    stopDrag() {
      this.drag.classList.remove("dragging");
      document.body.classList.remove("noselect");
      document.documentElement.removeEventListener("mousemove", this.doDrag, false);
      document.documentElement.removeEventListener("mouseup", this.stopDrag, false);
    },
  },
  mounted: function () {
    if (this.$route.params.view && this.$route.params.view != "") {
      this.active_view = parseInt(this.$route.params.view);
      this.getproject(this.$route.params.slug, this.$route.params.view);
      //this.openDetail();
    } else {
      this.getproject(this.$route.params.slug);
    }

    this.detail = true;
    let scope = this;

    setTimeout(() => {
      this.setInitalView();
      scope.drag = document.querySelector("#drag-me");
      scope.drag.addEventListener(
        "mousedown",
        function (innerE) {
          startX = innerE.clientX;
          startWidth = parseInt(
            document.defaultView.getComputedStyle(scope.drag.parentNode).width,
            10
          );
          document.documentElement.addEventListener("mousemove", scope.doDrag, false);
          document.documentElement.addEventListener("mouseup", scope.stopDrag, false);
        },
        false
      );
    }, 2000);

    /*  window.addEventListener("scroll", function () {
      scope.btt =
        document.querySelector(".main-section").getBoundingClientRect().top <= 0;
      scope.gtb =
        document.querySelector("footer").getBoundingClientRect().top -
          window.innerHeight >=
        0;
    }); */
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/views/project_detail";
</style>
